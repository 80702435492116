<!-- 挂单侧边栏面板 -->
<script setup>
import { useAppStore } from '@store/appStore';
import ListingPanelPc from './ListingPanelPc.vue';
import ListingPanelMob from './ListingPanelMob.vue';
const appStore = useAppStore();
</script>

<template>
  <ListingPanelPc/>
</template>

<style lang="scss" scoped></style>
