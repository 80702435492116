<!-- 单张 挂单 nft卡 -->
<script setup>
import { ref, computed, defineProps, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getFullNum, getTradeTypeCoin } from '@/utils/tools';
import useMarketStore from '@store/cattleMarketStore';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;

const marketStore = useMarketStore();

// 卡牌页面内容
const props = defineProps({
  // 要展示的类型
  cardItem: {
    type: Object,
    default: {
      id: 337,
      class: 1,
      name: '93',
      gender: 1,
      tradeType: 3,
      price: 0.009,
      usdt: 4.212,
      image: 'https://game.legendranch.app/legendaryranch/picture/bovine-hero/1-69.png'
    }
  },
  cardType: {
    type: String,
    default: 'planet'
  }
});

// 头部展示的名字
const headName = computed(() => {
  switch (props.cardType) {
    case 'blindBox':
      return `${$t('card.1')}`;

    case 'hero':
      return `${$t('card.3')} #${props.cardItem.id}`;

    case 'cattle':
      return `${$t('card.4')} #${props.cardItem.id}`;

    case 'planet':
      return `${$t('card.12')}`;
  }
});

/**
 * 获取币对价格及图标
 */
const coin = ref('bnb');
const coinVal = ref(0);

(() => {
  // 消耗的币
  coin.value = getTradeTypeCoin(props.cardItem?.tradeType);
  coinVal.value = marketStore.countingPrice({
    types: props.cardItem?.tradeType,
    amount: props.cardItem?.price
  });
})();

/**
 * 路由跳转详情
 * @param {Number} id 卡牌ID
 * @param {Number} tradeType 支付币种类型
 * @param {Number} price 价格
 * @param {String} type 类型：盲盒、牛牛、星球
 */
const router = useRouter();
const route = useRoute();
async function toDetail() {
  router.push({
    name: 'listingDetail',
    query: {
      id: props.cardItem.id,
      from: route.name,
      tradeType: props.cardItem.tradeType,
      price: props.cardItem.price,
      doller: coinVal.value,
      type: props.cardType
    }
  });
}

const tempImg =
  'https://game.legendranch.app/legendaryranch/picture/bovine-hero/1-17.png';
</script>

<template>
  <div class="nft-card-item-wrap" @click="toDetail">
    <div class="face">
      <!-- 状态 -->
      <div class="status">ON SALE</div>

      <img :src="props.cardItem.image" alt="" class="face-img" />
    </div>

    <!-- 名字和id -->
    <div class="name">{{ headName }}</div>

    <!-- 价格 -->
    <div class="price-container">
      <div :class="['coins', `icons-${coin}`]"></div>
      <div class="number">
        {{ Number(props.cardItem?.price).toFixed(3) }}
      </div>
      <div class="coin-name">
        {{ String(coin).toUpperCase() }}
      </div>
    </div>
    <!-- <div class="doller">≈${{ coinVal }}</div> -->
  </div>
</template>

<style lang="scss" scoped>
.nft-card-item-wrap {
  @include -width(3.15rem, 3.15rem, 2.95rem);
  font-size: 0.24rem;
  color: #fff;
  position: relative;
  cursor: pointer;

  .face {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.12rem;
    margin-bottom: 0.04rem;

    &-img {
      // width: 100%;
      @include -width(3.15rem, 3.15rem, 2.95rem);
    }
  }

  .name {
    font-size: 0.24rem;
    line-height: 0.28rem;
    text-align: center;
  }

  .status {
    @include -width(1.1rem, 1.1rem, 0.78rem);
    @include -height(0.36rem, 0.36rem, 0.28rem);
    background-color: #34286f;
    border-radius: 0.03rem;
    font-size: 0.12rem;
    @include absPos(0.12rem, 0.12rem, '', '');
    @include flexPos(center);
  }

  .price-container {
    margin-top: 0.06rem;
    @include flexPos(center);
    .number {
      font-size: 0.3rem;
      max-width: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0.04rem 0 0.06rem;
    }
    .icon-coin {
      font-size: 0.3rem;
    }
  }
}
.doller {
  margin: 0 auto;
  text-align: center;
  font-size: 0.24rem;
}
</style>
