<!-- 我的挂单列表 -->
<script setup>
import { reactive, ref, computed } from "vue";
import NftCardItem from "./components/NftCardItem.vue";
import ListingPanel from "./components/ListingPanel";
import {
  curNavType,
  showHistory
} from "./components/ListingPanel/useListingPanel";
import History from "./components/History.vue";
import { handleCopy } from "@/utils/tools.js";
import { onMounted } from "vue";
import $router from "@/router";
import useMarketStore from "@store/cattleMarketStore";
import useCattleMintStore from "@contractStore/cattleMintStore";
import { useAppStore } from "@store/appStore";

const appStore = useAppStore();
const marketStore = useMarketStore();
const mintStore = useCattleMintStore();

// 获取当前币价
(async () => {
  await marketStore.getCurrency();
})();

/**
 * 当前展示的卡牌列表
 */
const cardList = computed(() => {
  // 当前形式勾选的是盲盒
  if (curNavType.value?.name === "blindBox") {
    return mintStore.blindBoxList || [];
  }

  // 当前形式勾选的是牛牛
  if (curNavType.value?.name === "hero") {
    return mintStore.heroList || [];
  }

  // 当前形式勾选的是牛牛
  if (curNavType.value?.name === "cattle") {
    console.log("mintStore.cattleList", mintStore.cattleList);
    return mintStore.cattleList || [];
  }

  // 当前形式勾选的是星球
  if (curNavType.value?.name === "planet") {
    return mintStore.planetList || [];
  }

  return [];
});

onMounted(() => {
  handleCopy();
});

const currentPage = ref(1);
async function changeSellingList() {
  try {
    if (curNavType.value.name === "hero") {
      // 创世牛牛
      const heroRes = await marketStore.fetchCattleArray(
        curNavType.value.name,
        mintStore.heroIds.slice(
          (currentPage.value - 1) * 20,
          currentPage.value * 20
        )
      );
      console.log("My hero list...", mintStore.heroIds, marketStore.heroList);
      const heroList = [];
      // 创世牛牛信息
      for (let inx = 0; inx < heroRes.length; inx++) {
        // 价格信息
        for (let i = 0; i < mintStore.tradeList.length; i++) {
          if (mintStore.tradeList[i].id == heroRes[inx].id) {
            // console.log('sell..', res[inx], { ...res[inx], ...mintStore.tradeList[i] });
            heroList.push({ ...heroRes[inx], ...mintStore.tradeList[i] });
          }
        }
      }
      mintStore.heroList = heroList;
      // mintStore.totalNum = mintStore.heroIds.length;
    } else if (curNavType.value.name === "cattle") {
      // 普通牛牛
      const cattleRes = await marketStore.fetchCattleArray(
        curNavType.value.name,
        mintStore.cattleIds.slice(
          (currentPage.value - 1) * 20,
          currentPage.value * 20
        )
      );
      const cattleList = [];
      // 牛牛信息
      for (let inx = 0; inx < cattleRes.length; inx++) {
        // 价格信息
        for (let i = 0; i < mintStore.tradeList.length; i++) {
          if (mintStore.tradeList[i].id == cattleRes[inx].id) {
            cattleList.push({ ...cattleRes[inx], ...mintStore.tradeList[i] });
          }
        }
      }
      mintStore.cattleList = cattleList;
    } else if (curNavType.value.name === "planet") {
      // 星球
      const list = [];
      const _tradeList = mintStore.tradeList.slice(
        (currentPage.value - 1) * 20,
        currentPage.value * 20
      );
      for (let i = 0; i < _tradeList.length; i++) {
        const info = {
          id: _tradeList[i],
          cardType: 1,
          name: "card.12",
          image:
            "https://game.legendranch.app/legendaryranch/planet/homeland.png",
          desc: "card.15",
          population: 0,
          populationLimit: "10,000"
        };
        list.push({ ..._tradeList[i], ...info });
      }
      mintStore.planetList = list;
    } else if (curNavType.value.name === "blindBox") {
      // 盲盒
      const list = [];
      for (let i = 0; i < 20; i++) {
        const info = {
          name: "card.1",
          cardType: "blindBox",
          image: "https://game.legendranch.app/legendaryranch/boxgif/box.png",
          desc: "card.7"
        };
        list.push({
          ...mintStore.tradeList[(currentPage.value - 1) * 20 + i],
          ...info
        });
      }
      mintStore.blindBoxList = list;
    }
  } catch (error) {}
}

// 返回交易市场
function handleBack() {
  $router.push({ name: "nftMarket" });
}
</script>

<template>
  <div class="my-listing-wrap">
    <ListingPanel />

    <section class="nft-container" v-if="!showHistory">
      <header>
        <span v-show="appStore.curDevice == 'pc'">{{ $t('panel.27') }}</span>
      </header>

      <!-- 国庆前关闭（已开启） -->
      <div class="nft-list" v-show="cardList?.length">
        <NftCardItem
          :cardType="curNavType.name"
          :cardItem="item"
          v-for="(item, index) in cardList"
          :key="`${item.id} - ${index}`"
        />
      </div>

      <!-- 国庆前关闭（已开启） -->
      <van-empty
        v-show="!cardList.length"
        class="empty"
        :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
        :description="$t('panel.34')"
      />
      <!-- 分页器 -->
      <div class="pagtination-wrap">
        <el-pagination
          v-model:current-page="currentPage"
          background
          layout="prev, pager, next"
          @current-change="changeSellingList"
          :pager-count="5"
          :page-size="20"
          :total="mintStore.totalNum"
        ></el-pagination>
      </div>
    </section>

    <section class="history-container" v-if="showHistory">
      <header v-show="appStore.curDevice == 'pc'">{{ $t('panel.32') }}</header>

      <History />
    </section>
    <footer>
      <div class="btn" @click="handleBack">{{ $t('dialog.3') }}</div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.my-listing-wrap {
  @include nftImgBg;
  min-height: calc(100vh - $pcTopBarHeight);
  color: #fff;
  @include -media($pc, display, flex);

  > .listing-panel-wrap {
    flex: 0 0 3.63rem;
  }

  @media (max-width: $pad) {
    min-height: calc(100vh - $mobTopBarHeight);
    // padding: 0 0.4rem 1.16rem;
  }
}

.nft-container {
  @include -width(auto, auto, calc(100vw - 0.4rem));

  height: calc(100vh - $pcTopBarHeight);
  margin: 0.35rem 0.3rem;
  @media (max-width: $pad) {
    height: calc(75vh - $mobTopBarHeight);
  }
  overflow: auto;

  header {
    font-size: 0.5rem;
    @include -media($pc, margin, 0.99rem 0 0.5rem);
  }

  .nft-list {
    display: grid;
    @include -width(100%, 12.8rem, 100%);
    // @include flexPos(flex-start, flex-start);
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
    grid-gap: 0.4rem;
    grid-template-columns: repeat(auto-fit, 3.15rem);

    .nft-card-item-wrap {
      // margin: 0.3rem 0.36rem 0 0;
      // @include -media($phone, margin, 0 auto);
      // @include -media($pad, margin, 0 auto);
    }
  }
}

.history-container {
  width: 100%;
  header {
    font-size: 0.5rem;
    margin: 0.5rem 0 0.36rem;
  }
}

.empty {
  @include flexPos(center);
  flex-direction: column;
  margin: 0 auto;
  img {
    width: vw(300);
    height: vw(300);
  }
}

:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}

// 呼出面板按钮
footer {
  @include -media($pc, display, none);
  width: 100%;
  @include flexPos(center);
  padding-bottom: 0.8rem;
  .btn {
    @include flexPos(center);
    @include -width(6.5rem, 6.5rem, 0);
    @include -height(0.7rem, 0.7rem, 0);
    @include -font-size(0.28rem, 0.28rem, 0);
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 3px;
  }
}

// 分页器
.pagtination-wrap {
  @include flexPos(center);
  margin: 0.5rem;
  @include -media($phone, padding-bottom, 1.8rem);
  @include -media($pad, padding-bottom, 1.8rem);
  :deep() {
    .el-input__inner {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }

    button {
      background-color: transparent !important;
    }

    .el-pager {
      li {
        background-color: transparent !important;
      }
    }

    .el-pagination.is-background .el-pager li {
      background-color: black;
      color: white;
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      opacity: 0.2;
      // border: 1px solid #656565;

      &:not(.disable).active {
        background-color: transparent;
        font-weight: bold;
        opacity: 1;
      }
    }

    .el-pagination.is-background .btn-next {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }

    .el-pagination.is-background .btn-prev {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }

    .el-icon {
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      font-weight: bold !important;
    }
  }
}
</style>
