import { reactive, ref, computed, readonly } from 'vue';
import useCattleMintStore from '@contractStore/cattleMintStore';

/**
 * 我的挂单导航列表
 */
const navListState = reactive([
  {
    id: 1,
    tid: 3,
    name: 'planet',
    label: 'panel.8',
    active: true
  },
  {
    id: 2,
    tid: 1,
    name: 'hero',
    label: 'panel.16',
    active: false
  },
  {
    id: 3,
    tid: 1,
    name: 'cattle',
    label: 'panel.17',
    active: false
  },
  {
    id: 4,
    tid: 2,
    name: 'blindBox',
    label: 'panel.18',
    active: false
  }
]);

/**
 * 设置导航列表
 */
export function setNavList(id) {
  if (!id) {
    navListState.forEach(nav => {
      nav.active = false;
    });
    return;
  }
  navListState.forEach(nav => {
    nav.active = false;
    if (nav.id === id) {
      nav.active = true;
    }
  });
}

export const navList = readonly(navListState);

/**
 * 当前选择的 卡牌类型 hero cattle planet blindBox
 */
const curPickNavType = computed(() => {
  return navListState.find(item => item.active);
});
export const curNavType = readonly(curPickNavType);

/**
 * 展示历史状态
 */
const pickHistoryState = ref(false);

/**
 * 设置历史模块的展示
 */
export function SetHistory(payload) {
  pickHistoryState.value = payload;
}

export const showHistory = readonly(pickHistoryState);

export const defaultNav = readonly({
  id: 1,
  tid: 3,
  name: 'planet',
  label: 'panel.8',
  active: true
});
