<!-- 历史记录 -->
<script setup>
import { ref, computed, defineProps, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { getFullNum, getTradeTypeCoin, padNumber } from '@/utils/tools';
import useMarketStore from '@store/cattleMarketStore';
import { useAppStore } from '@store/appStore';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;

const appStore = useAppStore();
const nftMarketStore = useMarketStore();

const currentPage = ref(1);

watchEffect(async () => {
  if (appStore.defaultAccount == null) return;
  await init();
});

async function init() {
  await nftMarketStore.fetchHistory(appStore.defaultAccount);
}

console.log('nftMarketStore',nftMarketStore.historyList)

function goodType(item) {
  const t = String(item.goodsType);
  switch (t) {
    case '1':
      return item.name == ''
        ? `${$t('card.4')} #${item.tokenId}`
        : `${$t('card.3')} #${item.tokenId}`;
    case '2':
      return `${$t('card.1')}`;
    case '3':
      return `${$t('card.12')}`;
  }
}

/**
 * 时间显示未UTC00:00
 */
function getUtcTime(date) {
  // var utc = require('dayjs/plugin/utc');
  // dayjs.extend(utc);
  // return dayjs.utc(date * 1000).format('YYYY-MM-DD HH:mm:ss');
  return dayjs(date * 1000).format('YYYY-MM-DD HH:mm:ss');
}
</script>

<template>
  <div class="history-wrap">
    <table
      class="his-table"
      v-show="nftMarketStore.historyList?.length && appStore.curDevice == 'pc'"
    >
      <thead>
        <tr>
          <th style="white-space: nowrap">{{ $t('detail.28') }}</th>
          <th>{{ $t('detail.29') }}</th>
          <th>{{ $t('detail.30') }}</th>
          <th style="white-space: nowrap">{{ $t('detail.31') }}</th>
          <th style="white-space: nowrap">{{ $t('detail.34') }}</th>
        </tr>
      </thead>
      <tr v-for="item in nftMarketStore.historyList" :key="item.id">
        <td>{{ goodType(item) }}</td>
        <td style="white-space: nowrap">
          {{ item.status == 1 ? $t('detail.33') : $t('detail.32') }}
        </td>
        <td>{{ 1 }}</td>
        <td style="white-space: nowrap">
          <div class="price-detail-content">
            <div :class="['coins', `icons-${getTradeTypeCoin(item.tradeType)}`]"></div>
            <span style="font-size: 0.24rem; margin: 0 0.08rem"
              >{{ Number(item.price).toFixed(3)
              }}{{ getTradeTypeCoin(item.tradeType).toUpperCase() }}</span
            >
            <!-- <span style="font-size: 0.24rem">≈ ${{ item.usdt }}</span> -->
          </div>
        </td>
        <td>{{ getUtcTime(item.dealTime) }}</td>
      </tr>
    </table>
    <!-- mob 端 -->
    <div class="his-record-card" v-show="nftMarketStore.historyList && appStore.curDevice !== 'pc'">
      <div class="record-item" v-for="item in nftMarketStore.historyList" :key="item.id">
        <!-- 行 -->
        <div class="row-wrap">
          <div class="record-label-wrap">
            <div class="label">{{ $t('detail.28') }}</div>
            <span>{{ goodType(item) }}</span>
          </div>
          <div class="record-label-wrap">
            <div class="label">{{ $t('detail.29') }}</div>
            <span>{{ item.status == 1 ? $t('detail.33') : $t('detail.32') }}</span>
          </div>
        </div>
        <!-- 行 -->
        <div class="row-wrap">
          <div class="record-label-wrap">
            <div class="label">{{ $t('detail.30') }}</div>
            <span>{{ 1 }}</span>
          </div>
          <div class="record-label-wrap">
            <div class="label">{{ $t('detail.31') }}</div>
            <div class="price-detail-content">
              <div :class="['coins', `icons-${getTradeTypeCoin(item.tradeType)}`]"></div>
              <span class="price"
                >{{ Number(item.price).toFixed(3)
                }}{{ getTradeTypeCoin(item.tradeType).toUpperCase() }}</span
              >
            </div>
            <!-- <span class="dollar">≈ ${{ item.usdt }}</span> -->
          </div>
        </div>
        <!-- 末行 -->
        <div class="row-wrap">
          <div class="record-label-wrap">
            <div class="label">{{ $t('detail.34') }}</div>
            <span>{{ getUtcTime(item.dealTime) }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页器 -->
    <div class="pagtination-wrap" v-show="nftMarketStore.historyList?.length">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="10"
        :page-size="10"
        v-model:currentPage="currentPage"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
    <van-empty
      v-if="!nftMarketStore.historyList?.length"
      class="empty"
      :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
      :description="$t('panel.34')"
    />
  </div>
</template>

<style lang="scss" scoped>
.history-container {
  width: 100%;
  height: 100%;
}
.icon {
  width: 0.5rem;
  height: 0.5rem;
}
.his-table {
  width: 100%;
  margin: 0 auto;
  align-items: center;

  @include -font-size(0.24rem, 0.22rem, 0.22rem);

  td {
    // white-space: nowrap;
    vertical-align: middle;
    text-overflow: ellipsis;
    border-bottom: solid 1px rgba(208, 196, 240, 0.5);
    padding: 0.25rem 0.78rem;
    @include -media($phone, padding, 0);
    .price-detail-content {
      @include flexPos(center);

      .icon-coin {
        font-size: 0.24rem;
      }
    }
  }
  th {
    border-bottom: solid 1px rgba(208, 196, 240, 0.5);
    text-align: center;
    padding: 0.11rem 0.5rem;
    @include -media($phone, padding, 0.1rem);
    @include -font-size(0.22rem, 0.22rem, 0.22rem);
    font-family: georgia-bold;
    line-height: 0.3rem;
    color: #fff;
  }

  tr {
    text-align: center;
    &:first-child {
      td {
        border-top: none;
        font-weight: bold;
        padding: 0.25rem 0;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
        padding: 0.25rem 0;
      }
    }

    td {
      &:first-child {
        border-left: none;
        padding: 0.25rem 0;
      }

      &:last-child {
        border-right: none;
        padding: 0.25rem 0;
      }
    }
  }
}

.his-record-card {
  width: 100%;
  @include flexPos(flex-start, center);
  flex-direction: column;
  padding: 0.5rem 0.4rem 0.5rem 0.4rem;
  .record-item {
    width: 100%;
    flex-wrap: wrap;
    @include flexPos(flex-start, flex-start);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.12rem;
    margin-top: 0.2rem;
    padding: 0.2rem 0.3rem;
    .row-wrap {
      width: 100%;
      @include flexPos(flex-start, flex-start);
      border-bottom: 1px solid #3c3a4d;
      &:last-child {
        border-bottom: none;
      }
      .record-label-wrap {
        width: 50%;
        @include flexPos(center, flex-start);
        flex-direction: column;
        text-align: center;
        font-size: 0.24rem;
        line-height: 0.28rem;
        padding: 0.2rem 0;
        .label {
          font-size: 0.32rem !important;
          line-height: 0.38rem;
          margin-bottom: 0.08rem;
        }
        span {
          font-size: 0.28rem;
        }
        .price-detail-content {
          @include flexPos(center);
          .icon-coin {
            font-size: 0.24rem !important;
          }
          .price {
            font-size: 0.28rem;
            margin: 0.1rem 0.08rem;
          }
        }
        .dollar {
          font-size: 0.26rem;
          margin-left: 0.36rem;
        }
      }
    }
  }
}

.empty {
  height: 70vh;
  @include flexPos(center);
  text-align: center;
  flex-direction: column;
  margin-bottom: 0.2rem;
}
:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  @include flexPos(center);
  text-align: center;
  flex-direction: column;
  margin: 0 auto;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}

// 分页器
.pagtination-wrap {
  min-height: 0.5rem;
  @include flexPos(center);
  margin: 0.5rem;
  @include -media($phone, margin, 0.5rem 0);
  :deep(){

    .el-input__inner {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
    button {
      background-color: transparent !important;
    }
  
    .el-pager {
      li {
        background-color: transparent !important;
      }
    }
  
     .el-pagination.is-background .el-pager li {
      background-color: black;
      color: white;
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      opacity: 0.2;
      // border: 1px solid #656565;
  
      &:not(.disable).active {
        background-color: transparent;
        font-weight: bold;
        opacity: 1;
      }
    }
  
    .el-pagination.is-background .btn-next {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
     .el-pagination.is-background .btn-prev {
      background-color: black;
      color: white;
      // border: 1px solid #656565;
    }
  
    .el-icon {
      @include -font-size(0.4rem, 0.4rem, 0.2rem);
      font-weight: bold !important;
    }
  }
}

@media (min-width: $pad) and (max-width: 1580px) {
  .status-container {
    font-size: 0.2rem;
    th {
      padding: 0.11rem 0.15rem;
    }
    td {
      padding: 0.25rem 0.28rem;
    }
  }
}
</style>
