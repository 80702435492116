<!-- pc 挂单侧边栏面板 -->
<script setup>
import { ref, computed, watchEffect } from 'vue';
import {
  navList,
  setNavList,
  curNavType,
  showHistory,
  SetHistory,
  defaultNav,
} from './useListingPanel';
import { useAppStore } from '@store/appStore';
import $load from '@cps/GlobalLoading';
import i18n from '@/utils/i18n.js';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { PROXY_QUERY_ADDR } from '@/contracts/polygonAddress.js';
import { padNumber, toThousands, plusXing } from '@/utils/tools.js';
import commonObj from '@/contracts/commonContract.js';
import useCattleMintStore from '@contractStore/cattleMintStore';
import useMarketStore from '@store/cattleMarketStore';
import useAccountStore from '@store/accountStore';

const $t = i18n.global.t;
const $route = useRoute();
const appStore = useAppStore();
const accStore = useAccountStore();
const mintStore = useCattleMintStore();
const marketStore = useMarketStore();
mintStore.createContract();

(async () => {
  if($route.query.selectType) {
    pickNav('history')
  }else if (!$route.query.type) {
    await pickNav(curNavType.value);
  } else {
    const navLabel = { planet: 1, hero: 2, cattle: 3, blindBox: 4 };
    setNavList(navLabel[$route.query.type]);
    // 链不对
    if (!appStore.rightLink) {
      await appStore.chainChanged($route.meta.needChains[0]);
      return;
    }
    $load({ isShow: true });
    try {
      await getSellList();
    } catch (error) {
      console.log(error);
    }
    $load({ isShow: false });
  }
})();

const tempImg = computed(() => {
  return !accStore.avatar
    ? require('../../../../../assets/img/home/logo.png')//'https://game.legendranch.app/legendaryranch/picture/profile/0.png'
    : accStore.avatar;
});

/**
 * 选择导航栏分类
 */
async function pickNav(nav) {
  if (nav === 'history') {
    setNavList();
    SetHistory(true);
    console.log('his..', navList.value);
    return;
  }
  SetHistory(false);
  setNavList(nav.id);
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  $load({ isShow: true });
  try {
    await getSellList();
  } catch (error) {
    console.log(error);
  }
  $load({ isShow: false });
}

/**
 * 獲取挂售列表
 */
async function getSellList() {
  const tokenids = await mintStore.getSellList({
    goodsType: curNavType.value.tid,
    addr: appStore.defaultAccount,
  });
  console.log('tokenids',tokenids.length)
  if (curNavType.value.name === 'hero' || curNavType.value.name === 'cattle') {
    // 查询是否是创世牛，返回创世Ids和普通Ids
    const { contract } = new commonObj();
    const resp = await contract.checkCreation(tokenids);
    mintStore.heroIds = resp.map((it) => +it); // 创世
    mintStore.cattleIds = tokenids.filter(function (v) {
      return mintStore.heroIds.indexOf(v) == -1;
    });
    console.log('Sell token..', tokenids, mintStore.heroIds, mintStore.cattleIds);
    console.log('mintStore.heroIds',mintStore.heroIds.length)
    // 创世牛牛信息
    const heroRes = await marketStore.fetchCattleArray(
      curNavType.value.name,
      mintStore.heroIds.slice(0, 20)
    );
    const heroList = [];
    for (let inx = 0; inx < heroRes?.length; inx++) {
      // 价格信息
      for (let i = 0; i < mintStore.tradeList.length; i++) {
        if (mintStore.tradeList[i].id == heroRes[inx].id) {
          heroList.push({ ...heroRes[inx], ...mintStore.tradeList[i] });
        }
      }
    }
    if (curNavType.value.name === 'hero') {
      mintStore.totalNum = mintStore.heroIds?.length;
    }
    mintStore.heroList = heroList;
    // 牛牛信息
    const cattleRes = await marketStore.fetchCattleArray(
      curNavType.value.name,
      mintStore.cattleIds.slice(0, 20)
    );
    const cattleList = [];

    for (let inx = 0; inx < cattleRes?.length; inx++) {
      // 价格信息
      for (let i = 0; i < mintStore.tradeList.length; i++) {
        if (mintStore.tradeList[i].id == cattleRes[inx].id) {
          cattleList.push({ ...cattleRes[inx], ...mintStore.tradeList[i] });
        }
      }
    }

    if (curNavType.value.name === 'cattle') {
      mintStore.totalNum = mintStore.cattleIds.length;
    }
    mintStore.cattleList = cattleList;
  } else if (curNavType.value.name === 'blindBox') {
    // 盲盒
    const list = [];
    for (let i = 0; i < mintStore.tradeList.length; i++) {
      const info = {
        name: 'card.1',
        cardType: 'blindBox',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/box.png',
        desc: 'card.7',
      };
      list.push({ ...mintStore.tradeList[i], ...info });
    }
    mintStore.blindBoxList = list;
    mintStore.totalNum = list.length;
  } else if (curNavType.value.name === 'planet') {
    // 星球
    const list = [];
    const _tradeList = mintStore.tradeList.slice(0,20)
    for (let i = 0; i < _tradeList.length; i++) {
      const info = {
        id: _tradeList[i],
        cardType: 1,
        name: 'card.12',
        image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
        desc: 'card.15',
        population: 0,
        populationLimit: '10,000',
      };
      list.push({ ..._tradeList[i], ...info });
    }
    mintStore.planetList = list;
    mintStore.totalNum = mintStore.tradeList.length;
  }
}
</script>

<template>
  <div class="listing-panel-wrap">
    <!-- 头像以及个人信息 -->
    <div style="margin-left: 0.76rem" v-if="appStore.curDevice === 'pc'">
      <img :src="tempImg" alt="" class="avatar" />
      <div class="addr">
        <div class="content">{{ plusXing(appStore.defaultAccount, 6, 4) }}</div>
        <i
          :data-clipboard-text="appStore.defaultAccount"
          class="iconfont icon-cpy cpy-btn"
          v-show="appStore.defaultAccount"
        ></i>
      </div>
    </div>

    <div class="navs">
      <header :class="['head']">
        <!-- PC 端 -->
        <div v-show="appStore.curDevice == 'pc'" :class="['NFT', { disable: showHistory }]">
          NFT
        </div>
        <!-- mob 端 -->
        <div
          :class="['NFT', { disable: showHistory }]"
          @click="pickNav(defaultNav)"
          v-show="appStore.curDevice !== 'pc'"
        >
          NFT
        </div>
        <div
          @click="pickNav('history')"
          :class="['mob-history', { active: showHistory }]"
          v-show="appStore.curDevice !== 'pc'"
        >
          {{ $t('panel.32') }}
        </div>
      </header>

      <!-- pc列表 -->
      <template v-if="appStore.curDevice === 'pc'">
        <div class="list-container">
          <li
            :class="[{ active: nav.active }]"
            @click="pickNav(nav)"
            v-for="nav in navList"
            :key="nav.id"
          >
            {{ $t(nav.label) }}
          </li>
        </div>

        <div @click="pickNav('history')" :class="['history', { active: showHistory }]">
          {{ $t('panel.32') }}
        </div>
      </template>

      <!-- 移动端列表 -->
      <template v-else>
        <div class="list-container-mob" v-show="!showHistory">
          <li
            :class="[{ active: nav.active }]"
            @click="pickNav(nav)"
            v-for="nav in navList"
            :key="nav.id"
          >
            {{ $t(nav.label) }}
          </li>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listing-panel-wrap {
  // width: 3.63rem;
  padding: 0.24rem 0 0 0;
}

.avatar {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  border: solid 0.03rem #fff;
}
.addr {
  margin: 0.23rem 0 0.5rem;
  @include flexPos(flex-start);
  .content {
    margin-right: 0.1rem;
    font-size: 0.18rem;
  }

  .icon-cpy {
    font-size: 0.18rem;
    transform: translateY(-0.03rem);
  }
}

.navs {
  .content {
    padding-left: 0.57rem;
  }

  header {
    @include -font-size(0.6rem, 0.6rem, 0.48rem);
    margin-bottom: 0.2rem;
    font-weight: bold;

    @media (min-width: $pad) {
      &.disable {
        color: #a6a6a6;
      }
    }
  }

  .head {
    @include flexPos(flex-start, center);
    .NFT {
      // font-size: 0.6rem;
      @include -font-size(0.48rem, 0.48rem, 0.48rem);
      @include -padding-left(0.2rem, 0, 0.8rem);
      display: flex;
      text-align: left;
      align-items: center;
      font-weight: bold;
      color: #fff;
      @include -media($pc, background, linear-gradient(90deg, #402866 0%, rgba(0, 0, 0, 0) 100%));
      &.disable {
        color: #a6a6a6;
        font-size: 0.3rem;
        padding-right: 0.1rem;
        margin-right: 0.1rem;
        @include -media($phone, border-right, 1px solid #a6a6a6);
        @include -media($pc, background, none);
      }
    }
    .mob-history {
      color: #a6a6a6;
      font-size: 0.3rem;
      padding-left: 0.1rem;
      margin-left: 0.1rem;
      border-left: 1px solid #a6a6a6;
      &.active {
        color: #fff;
        font-size: 0.5rem;
        border-left: none;
      }
    }
  }

  .list-container {
    // border-bottom: solid 1px #fff;
    position: relative;
    padding: 0 0 0.37rem 0.28rem;
    @include -padding-left(0, 0, 1.08rem);
    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: #fff;
      width: 1.88rem;
      position: absolute;
      left: 0.8rem;
      bottom: 0;
    }

    > li {
      font-size: 0.24rem;
      margin-top: 0.28rem;
      color: #a6a6a6;
      cursor: pointer;

      &.active {
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .list-container-mob {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem 0 0.2rem;

    li {
      text-align: center;
      width: 1.86rem;
      height: 0.52rem;
      line-height: 0.52rem;
      font-size: 0.24rem;
      border: solid 0.02rem #fff;
      border-radius: 0.04rem;
      cursor: pointer;
      &.active {
        background-color: #5b3bb7;
        border: solid 0.02rem transparent;
      }
    }
  }

  .history {
    @include -padding-left(0.2rem, 0, 0.8rem);
    font-size: 0.3rem;
    margin-top: 0.1rem;
    color: #a6a6a6;
    cursor: pointer;
    &.active {
      color: #fff;
      font-weight: bold;
      @include -font-size(0.48rem, 0.48rem, 0.48rem);
      display: flex;
      text-align: left;
      align-items: center;
      font-weight: bold;
      color: #fff;
      @include -media($pc, background, linear-gradient(90deg, #402866 0%, rgba(0, 0, 0, 0) 100%));
    }
  }
}
</style>
